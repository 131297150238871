nav {
  transition: all 0.3s ease-in-out;
  position: relative;
}

nav.active {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 99999;
  /* transform: translateY(5px); */
}

.promo-banner {
  transition: all 0.3s ease-in-out;
}
ul.custom-design li{
  width: auto;
}
/* ul.custom-design li {
  width: 16.66% !important;
  text-align: center;
}

ul.custom-design li a {
  padding: 0 !important;
  padding: 0px 10px !important;
}

ul.custom-design li:nth-child(2),
ul.custom-design li:nth-child(3) {
  width: 27% !important;
} */

.icon-box:hover .icon-wrapper svg {
  color: white;
  stroke: white;
}

.icon-box:hover .icon-wrapper svg circle {
  stroke: white;
}

.icon-box:hover .icon-wrapper svg path {
  fill: white;
}

/* .icon-box:hover:after {
  background-color: #ffffff36;
} */

.image-box:after {
  content: "";
  width: 200px;
  height: 200px;
  border-radius: 50%;
  background-color: #ffffff36;
  position: absolute;
  top: -20%;
  right: -20%;
  z-index: 10;
  transition: top 0.2s ease-in-out, width 0.5s, height 0.5s;
}

.image-box:hover:after {
  width: 260px;
  height: 260px;
}

.icon-box:hover .content-area a button {
  background-color: #243656 !important;
}

.testimaol-item:hover .bg-white {
  box-shadow: -1px 4px 20px #ffffff6e;
}

.field-container .custom-input-design {
  border-color: #ebebeb;
}

.field-container .custom-input-design:hover {
  border-color: #3f7fae;
}

.group[data-focus="true"] .group-data-\[focus\=true\]\:border-default-foreground {
  border-color: #3f7fae !important;
}

.field-container button {
  max-height: 49px !important;

  min-height: 49px;
}

.field-container .group[data-filled="true"] button label {
  font-size: 11px !important;
}

.field-container .group[data-filled="true"] button>div {
  padding-top: 10px;
}

/* slider css end */
@media only screen and (max-width: 676px) {
  ul.custom-design li {
    text-align: left;
    padding: 10px 0px;
  }

  .testimaol-item:hover .bg-white {
    box-shadow: none;
  }
}

.bg-pattern {
  background-image: url("../../src/assets/images/pattern.png");
  background-size: cover;
  background-position: center center;
}

.Toastify__toast-container {
  z-index: 99999 !important;
}

.zoom-img {
  width: 300px;
  height: 300px;
  overflow: hidden;
}

.zoom-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: all 0.3s ease;
}

.zoom-img img:hover {
  transform: scale(1.2);
}

@supports (-webkit-appearance: none) or (-moz-appearance: none) {
  .checkbox-wrapper-13 input[type="checkbox"] {
    --active: #c70048;
    --active-inner: #fff;
    --focus: 2px rgba(39, 94, 254, 0.3);
    --border: #000000;
    --border-hover: #c70048;
    --background: #fff;
    --disabled: #f6f8ff;
    --disabled-inner: #d19eb1;
    -webkit-appearance: none;
    -moz-appearance: none;
    height: 21px;
    outline: none;
    display: inline-block;
    vertical-align: top;
    position: relative;
    margin: 0;
    cursor: pointer;
    border: 1px solid var(--bc, var(--border));
    background: var(--b, var(--background));
    transition: background 0.3s, border-color 0.3s, box-shadow 0.2s;
  }

  .checkbox-wrapper-13 input[type="checkbox"]:after {
    content: "";
    display: block;
    left: 0;
    top: 0;
    position: absolute;
    transition: transform var(--d-t, 0.3s) var(--d-t-e, ease),
      opacity var(--d-o, 0.2s);
  }

  .checkbox-wrapper-13 input[type="checkbox"]:checked {
    --b: var(--active);
    --bc: var(--active);
    --d-o: 0.3s;
    --d-t: 0.6s;
    --d-t-e: cubic-bezier(0.2, 0.85, 0.32, 1.2);
  }

  .checkbox-wrapper-13 input[type="checkbox"]:disabled {
    --b: var(--disabled);
    cursor: not-allowed;
    opacity: 0.9;
  }

  .checkbox-wrapper-13 input[type="checkbox"]:disabled:checked {
    --b: var(--disabled-inner);
    --bc: var(--border);
  }

  .checkbox-wrapper-13 input[type="checkbox"]:disabled+label {
    cursor: not-allowed;
  }

  .checkbox-wrapper-13 input[type="checkbox"]:hover:not(:checked):not(:disabled) {
    --bc: var(--border-hover);
  }

  .checkbox-wrapper-13 input[type="checkbox"]:not(.switch) {
    width: 21px;
  }

  .checkbox-wrapper-13 input[type="checkbox"]:not(.switch):after {
    opacity: var(--o, 0);
  }

  .checkbox-wrapper-13 input[type="checkbox"]:not(.switch):checked {
    --o: 1;
  }

  .checkbox-wrapper-13 input[type="checkbox"]+label {
    display: inline-block;
    vertical-align: middle;
    cursor: pointer;
    margin-left: 4px;
  }

  .checkbox-wrapper-13 input[type="checkbox"]:not(.switch) {
    border-radius: 4px;
  }

  .checkbox-wrapper-13 input[type="checkbox"]:not(.switch):after {
    width: 5px;
    height: 10px;
    border: 3px solid var(--active-inner);
    border-top: 0;

    border-left: 0;
    left: 7px;
    top: 4px;
    transform: rotate(var(--r, 20deg));
  }

  .checkbox-wrapper-13 input[type="checkbox"]:not(.switch):checked {
    --r: 43deg;
  }
}

.checkbox-wrapper-13 * {
  box-sizing: inherit;
}

.checkbox-wrapper-13 *:before,
.checkbox-wrapper-13 *:after {
  box-sizing: inherit;
}

.ql-toolbar.ql-snow {
  display: none !important;
}

.ql-toolbar.ql-snow {
  padding: 0px !important;
}

.ql-container.ql-snow {
  border: none !important;
}

.ql-editor {
  padding: 0px !important;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.rotate-icon {
  animation: rotate 6s linear infinite;
}

/* styles.css */
.custom-progress-container {
  position: relative;
  height: 12px;
  background-color: #e0e0e0;
  border-radius: 10px;
  overflow: visible;
  /* Allow overflow to show the round indicator outside */
  width: 100%;
}

.custom-progress-bar {
  height: 100%;
  background-color: #c70048;
  border-radius: 10px 0 0 10px;
  transition: width 0.3s ease-in-out;
}

.round-indicator {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 40px;
  height: 40px;
  border: 4px solid white;
  background-color: #c70048;
  border-radius: 50%;
}

.customClass {
  padding: 10px;
}

.customClass:after {
  content: "✔";
  font-size: 12px;
  font-weight: bolder;
  line-height: 16px;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 10;
  transform: translate(-50%, -50%);
}

.linkComponentPadding {
  padding: 16px 32px 16px 32px !important;
}

.custom-color button {
  border-color: black !important;
  border-radius: 8px !important;
  border-width: 1px !important;
}

.custom-color2 button,
input:where([type="button"]),
input:where([type="reset"]),
input:where([type="submit"]) {
  -webkit-appearance: button;
  background-color: #fafafa;
  color: black;
}

.react-international-phone-input-container .react-international-phone-input {
  overflow: visible;
  height: 3rem !important;
  box-sizing: border-box;
  padding: 0 8px;
  border: 1px solid #ebebeb !important;
  border-radius: 12px !important;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  margin: 0;
  background-color: var(--react-international-phone-background-color, white);
  width: 100% !important;
  --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05) !important;
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color) !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}

.react-international-phone-country-selector-button {
  display: flex;
  height: 3rem !important;
  width: 4rem !important;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  padding: 0;
  border: 1px solid #ebebeb !important;
  border-radius: 12px !important;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  margin: 0;
  appearance: button;
  -webkit-appearance: button;
  background-color: var(--react-international-phone-country-selector-background-color,
      var(--react-international-phone-background-color, white));
  --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05) !important;
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color) !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  cursor: pointer;
  text-transform: none;
  -webkit-user-select: none;
  user-select: none;
}

.Toastify__toast-theme--light {
  background: #f7e9e9 !important;
}

.p-editor-container .ql-editor img {
  margin-top: 8px;
  float: none !important;
  max-width: 400px !important;
  width: 100% !important;
  border-radius: 25px !important;
  cursor: pointer !important;
  transition: max-width 0.3s ease-in-out, transform 0.3s ease-in-out;
  /* Add smooth transition */
}

.p-editor-container .ql-editor img:hover {
  max-width: 600px !important;
}

/* .hiden-editor .ck-editor .ck-editor__top {
  display: none !important;
}

.hiden-editor .ck-editor .ck-editor__main > div {
  border: none !important;
  padding: 0;
  box-shadow: none !important;
} */
.banner-flag-area {
  background-image: url(../assets/images/flag.webp);
  background-size: 100% 100%;
  background-position: bottom left;
  object-fit: contain;
  background-repeat: no-repeat;
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(199, 0, 72, 0.7);
  }

  70% {
    box-shadow: 0 0 0 10px rgba(199, 0, 72, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(199, 0, 72, 0);
  }
}

.feature-card:hover .icon-wrapper {
  animation: pulse 1.5s infinite;
}

.logo-area:before {
  content: "";
  width: 80px;
  height: 80px;
  position: absolute;
  right: 2%;
  bottom: -40%;
  border: 1.5px solid #c70048;
  border-radius: 50%;
  z-index: -0;
}

.logo-area:after {
  content: "";
  width: 80px;
  height: 80px;
  position: absolute;
  left: -11%;
  top: -40%;
  border: 1.5px solid #c70048;
  border-radius: 50%;
  z-index: -0;
}

@media only screen and (max-width: 600px) {

  .logo-area:before,
  .logo-area:after {
    content: none;
  }

}

.value-pointer:after {
  content: "";
  width: 14px;
  height: 14px;
  background-color: #243656;
  position: absolute;
  bottom: 0px;
  z-index: -30;
  transform: rotate(45deg);
}

.paymentShadow {
  box-shadow: 5px 12px 5px 0px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 5px 12px 5px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 5px 12px 5px 0px rgba(0, 0, 0, 0.75);
}

.ql-editor p span {
  background-color: transparent !important;
  font-size: 500;
}

.otpCodeClass {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
}

.swiper-button-next:after,
.swiper-rtl .swiper-button-prev:after {
  content: "next";
  display: none !important;
}

.swiper-button-prev:after,
.swiper-rtl .swiper-button-next:after {
  content: "prev";

  display: none !important;
}

.blinking-text {

  animation: blink 1s infinite;
}

@keyframes blink {

  0%,
  100% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }
}

@media only screen and (max-width: 767px) {
  .flex.w-screen.h-\[100dvh\].fixed.inset-0.z-50.overflow-x-auto.justify-center.items-center.sm\:items-center.\[--scale-enter\:100\%\].\[--scale-exit\:100\%\].\[--slide-enter\:0px\].\[--slide-exit\:80px\].sm\:\[--scale-enter\:100\%\].sm\:\[--scale-exit\:103\%\].sm\:\[--slide-enter\:0px\].sm\:\[--slide-exit\:0px\] section#\:rh\: {
    max-height: 80%;
    overflow-y: scroll;
  }
}